import React from 'react'
import Drops from './atomicAssets/Drops'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import PageHeader from './PageHeader'

const About = () => {
    return <>
        <Container disableGutters maxWidth="sm" component="main" sx={{pt: 8, pb: 6}}>
            <PageHeader title={'Numismatium - build your own collection'}/>
            <Typography variant="h5" align="center" color="text.secondary" component="p">
                DAPP for Numismatist. We digitize real coins and sell them as NFTs.<br/>We created coin packs. You can
                buy it here.
            </Typography>
        </Container>
        <Drops/>
    </>
}

export default About