import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {withUAL} from 'ual-reactjs-renderer'
import {useDispatch, useSelector} from 'react-redux'
import BigNumber from 'bignumber.js'
import Grid from '@mui/material/Grid'
import CardMedia from '@mui/material/CardMedia'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import {IPFS_URL} from '../../utils/constants'
import {sendingTransaction, sleep} from '../../utils/helpers'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import {getDelphiMedian} from '../../store/appSlice'
import {InputAdornment, TextField, Tooltip} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {requestAuctions} from '../../store/atomicAssetsSlice'

const AuctionShort = (props) => {
    const [inputBid, setInputBid] = useState('')

    const delphiMedian = useSelector(getDelphiMedian)

    const waxPrice = parseFloat(props.item.bid)
    const usdPrice = waxPrice * delphiMedian / 10000
    let imgSrc = '/images/image-placeholder.png'
    if (props.item.data.img) {
        imgSrc = `${IPFS_URL}${props.item.data.img}`
    }
    let endTime = (new Date(parseInt(props.item.end_time) * 1000)).toString()
    endTime = endTime.substring(0, endTime.indexOf(' GMT'))
    const auctionStatus = props.item.end_time > Date.now() / 1000
    const bidArr = props.item.bid.split(' ')
    const bid = {
        amount: parseFloat(bidArr[0]),
        symbol: bidArr[1],
        precision: bidArr[0].split('.')[1].length,
    }
    let nextBid = bid
    if (props.item.bidder !== '') {
        nextBid.amount *= 1.05
    }

    const dispatch = useDispatch()

    const handleBid = async () => {
        const quantity = `${parseFloat(inputBid).toFixed(bid.precision)} ${bid.symbol}`
        const tr = {
            actions: [{
                account: 'eosio.token',
                name: 'transfer',
                authorization: [{
                    actor: props.ual.activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    from: props.ual.activeUser.accountName,
                    to: 'thehousemrkt',
                    quantity: quantity,
                    memo: `bid ${props.item.asset_id}`,
                }
            }],
        }
        const callback = async () => {
            await sleep(3000)
            dispatch(requestAuctions())
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }
    const handleClaim = async () => {
        const tr = {
            actions: [{
                account: 'thehousemrkt',
                name: 'claimauction',
                authorization: [{
                    actor: props.ual.activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    asset_id: props.item.asset_id,
                }
            }],
        }
        const callback = async () => {
            await sleep(3000)
            dispatch(requestAuctions())
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }
    const onChangeHandler = (e) => {
        let value = e.target.value.replace(',', '.')
        if (value.split('.')[1] && value.split('.')[1].length > bid.precision) {
            return
        }
        if (value.startsWith('00')) {
            return
        }
        if (value !== '' && !value.endsWith('.') && !value.endsWith('0')) {
            if (new BigNumber(value).isNaN()) {
                return
            }
            value = new BigNumber(value).toString()
        }
        setInputBid(value)
    }

    return <Grid
        item
        key={props.item.asset_id}
        xs={12}
        sm={6}
        md={4}
    >
        <Card>
            <CardMedia
                component="img"
                image={imgSrc}
                alt={props.item.data.name}
            />
            <CardHeader
                title={props.item.data.name}
                subheader={props.item.data.description}
                titleTypographyProps={{align: 'center'}}
                subheaderTypographyProps={{
                    align: 'center',
                }}
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[700],
                }}
            />
            <CardContent>
                <Typography
                    variant="h5"
                    color="text.primary"
                    align="center"
                >
                    {waxPrice.toFixed(2)} WAX
                </Typography>
                <Typography
                    variant="h6"
                    color="text.secondary"
                    align="center"
                >
                    ({usdPrice.toFixed(2)} USD)
                </Typography>
                <Typography
                    component={'p'}
                    variant="subtitle1"
                    align="center"
                >
                    {props.item.bidder !== '' ? `Current bidder: ${props.item.bidder}` : 'No bids'}
                </Typography>
                <Typography
                    component={'p'}
                    variant="subtitle1"
                    align="center"
                >
                    Status: {auctionStatus ? 'active' : 'closed'}
                    <Tooltip title={`End time: ${endTime}`}>
                        <InfoOutlinedIcon color="primary" fontSize="small"/>
                    </Tooltip>
                </Typography>
                {auctionStatus &&
                    <TextField
                        disabled={!auctionStatus}
                        id="outlined-basic"
                        label="You bid"
                        variant="outlined"
                        sx={{mt: 2}}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{bid.symbol}</InputAdornment>
                        }}
                        placeholder={nextBid.amount.toFixed(8)}
                        value={inputBid}
                        onChange={onChangeHandler}
                    />
                }
            </CardContent>
            <CardActions>
                {auctionStatus ?
                    <Button
                        fullWidth
                        variant={'contained'}
                        onClick={handleBid}
                        disabled={!props.ual.activeUser}
                    >
                        Bid
                    </Button>
                    :
                    props.ual.activeUser && props.item.bidder === props.ual.activeUser.accountName ?
                        <Button
                            fullWidth
                            variant={'contained'}
                            color={'secondary'}
                            onClick={handleClaim}
                        >
                            Claim
                        </Button>
                        :
                        null
                }
            </CardActions>
        </Card>
    </Grid>
}

AuctionShort.propTypes = {
    item: PropTypes.object.isRequired,
    ual: PropTypes.object.isRequired,
}
export default withUAL(AuctionShort)
