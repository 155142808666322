import {configureStore} from '@reduxjs/toolkit'
import appReducer from './appSlice'
import atomicAssetsReducer from './atomicAssetsSlice'

const store = configureStore({
    reducer: {
        app: appReducer,
        atomicAssets: atomicAssetsReducer,
    },
})

export default store
