import React, {useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import {getAuctions, requestAuctions, setAuctions} from '../../store/atomicAssetsSlice'
import {CircularProgress} from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import AuctionShort from './AuctionShort'
import PageHeader from '../PageHeader'

const Auctions = () => {
    console.log('render Auctions')
    const auctions = useSelector(getAuctions)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(requestAuctions())
        return () => {
            dispatch(setAuctions(null))
        }
    }, [])
    console.log(auctions)

    const auctionList = useMemo(() => {
        return auctions ? auctions.map((item) => (
            <AuctionShort
                key={item.asset_id}
                item={item}
            />
        )) : []
    }, [auctions])

    return <Container maxWidth="md" component="main" sx={{mt: 5}}>
        <PageHeader title={'Auctions'}/>
        {auctions ?
            auctionList.length > 0 ?
                <Grid container spacing={5} alignItems="flex-end" sx={{display: '-webkit-box'}}>
                    {auctionList}
                </Grid>
                :
                <Box sx={{textAlign: 'center'}}>
                    <Typography>
                        {'Auction has not started yet'}
                    </Typography>
                </Box>
            :
            <Box sx={{textAlign: 'center'}}>
                <CircularProgress/>
            </Box>
        }
    </Container>
}

export default Auctions
