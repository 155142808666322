import * as waxjs from '@waxio/waxjs/dist'

class WaxApi {
    constructor() {
        this.api = new waxjs.WaxJS({
            rpcEndpoint: 'https://wax.greymass.com',
            tryAutoLogin: false,
        })
    }
}

export const waxApi = new WaxApi()
