import React from 'react'
import PropTypes from 'prop-types'
import {withUAL} from 'ual-reactjs-renderer'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import Grid from '@mui/material/Grid'
import CardMedia from '@mui/material/CardMedia'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import {IPFS_URL} from '../../utils/constants'
import {sendingTransaction, sleep} from '../../utils/helpers'

const PackShort = (props) => {
    let imgSrc = '/images/image-placeholder.png'
    if (props.item.data.img) {
        imgSrc = `${IPFS_URL}${props.item.data.img}`
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const claim = async () => {
        const tr = {
            actions: [{
                account: 'atomicpacksx',
                name: 'claimunboxed',
                authorization: [{
                    actor: props.ual.activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    pack_asset_id: props.item.asset_id,
                    origin_roll_ids: Array(parseInt(props.item.data.coinCount)).fill().map((_, i) => i)
                }
            }],
        }
        const callback = async () => {
            await sleep(3000)
            navigate('/coins')
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }
    const unpack = async () => {
        const tr = {
            actions: [{
                account: 'atomicassets',
                name: 'transfer',
                authorization: [{
                    actor: props.ual.activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    from: props.ual.activeUser.accountName,
                    to: 'atomicpacksx',
                    asset_ids: [props.item.asset_id],
                    memo: 'unbox',
                },
            }]
        }
        const callback = async () => {
            await sleep(3000)
            navigate('/unclaimed')
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }

    return <Grid
        item
        key={props.item.asset_id}
        xs={12}
        sm={6}
        md={4}
    >
        <Card>
            <CardMedia
                component="img"
                image={imgSrc}
                alt={props.item.data.name}
            />
            <CardHeader
                title={props.item.data.name}
                subheader={props.item.data.description}
                titleTypographyProps={{align: 'center'}}
                subheaderTypographyProps={{
                    align: 'center',
                }}
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[700],
                }}
            />
            <CardActions>
                {parseInt(props.item.burned_at_block) > 0 ?
                    <Button
                        fullWidth
                        variant={'contained'}
                        onClick={claim}
                    >
                        Claim
                    </Button>
                    :
                    <Button
                        fullWidth
                        variant={'contained'}
                        onClick={unpack}
                    >
                        Open pack
                    </Button>
                }
            </CardActions>
        </Card>
    </Grid>
}

PackShort.propTypes = {
    item: PropTypes.object.isRequired,
    ual: PropTypes.object.isRequired,
}
export default withUAL(PackShort)
