import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import {withUAL} from 'ual-reactjs-renderer'
import DropShort from './DropShort'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'

const Drops = (props) => {
    console.log('render Drops')
    const drops = [51174]

    let dropList = []
    dropList = useMemo(() => {
        return drops.map((item) => (
            <DropShort
                key={item}
                id={item}
                ual={props.ual}
            />
        ))
    }, [drops])

    return <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
            {dropList}
        </Grid>
    </Container>
}

Drops.propTypes = {
    ual: PropTypes.object.isRequired,
}
export default withUAL(Drops)
