import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {withUAL} from 'ual-reactjs-renderer'
import {
    getDropDetail,
    getTemplate,
    requestDrop,
    requestTemplate,
    setDropDetail,
    setTemplate
} from '../../store/atomicAssetsSlice'
import Grid from '@mui/material/Grid'
import {CardMedia, CircularProgress} from '@mui/material'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import {IPFS_URL} from '../../utils/constants'
import {getDelphiMedian} from '../../store/appSlice'
import {sendingTransaction, sleep} from '../../utils/helpers'
import {redirect} from 'react-router-dom'

const DropShort = (props) => {
    const drop = useSelector(getDropDetail(props.id))
    const templateId = drop ? drop.assets_to_mint[0].template_id : 0
    const template = useSelector(getTemplate(templateId))
    const delphiMedian = useSelector(getDelphiMedian)

    let imgSrc = '/images/image-placeholder.png'
    let packsRemained = 0
    let waxPrice = 0
    if (drop) {
        waxPrice = (parseFloat(drop.listing_price) / delphiMedian * 10000 * 1.005)
        packsRemained = drop.max_claimable - drop.current_claimed
        if (template && template.immutable_data && template.immutable_data.img) {
            imgSrc = `${IPFS_URL}${template.immutable_data.img}`
        }
    }

    const dispatch = useDispatch()

    useEffect(() => {
        if (drop) {
            dispatch(requestTemplate(templateId))
        } else {
            dispatch(requestDrop(props.id))
        }
    }, [drop])
    useEffect(() => {
        return () => {
            dispatch(setDropDetail({id: props.id, detail: null}))
            dispatch(setTemplate(templateId))
        }
    }, [])

    const buyDrop = async () => {
        let quantity = `${waxPrice.toFixed(8)} WAX`
        const tr = {
            actions: [
                {
                    account: 'atomicdropsx',
                    name: 'assertdrop',
                    authorization: [{
                        actor: props.ual.activeUser.accountName,
                        permission: 'active',
                    }],
                    data: {
                        assets_to_mint_to_assert: drop.assets_to_mint,
                        drop_id: drop.drop_id,
                        listing_price_to_assert: drop.listing_price,
                        settlement_symbol_to_assert: drop.settlement_symbol
                    }
                },
                {
                    account: 'eosio.token',
                    name: 'transfer',
                    authorization: [{
                        actor: props.ual.activeUser.accountName,
                        permission: 'active',
                    }],
                    data: {
                        from: props.ual.activeUser.accountName,
                        to: 'atomicdropsx',
                        quantity: quantity,
                        memo: 'deposit',
                    }
                },
                {
                    account: 'atomicdropsx',
                    name: 'claimdrop',
                    authorization: [{
                        actor: props.ual.activeUser.accountName,
                        permission: 'active',
                    }],
                    data: {
                        claim_amount: 1,
                        claimer: props.ual.activeUser.accountName,
                        country: 'EN',
                        drop_id: drop.drop_id,
                        intended_delphi_median: delphiMedian,
                        referrer: 'atomichub'
                    }
                },
            ],
        }
        const callback = async () => {
            await sleep(3000)
            dispatch(redirect('/packs'))
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }

    return <Grid
        item
        key={props.id}
        xs={12}
        sm={6}
        md={4}
    >
        {!drop || !template ?
            <CircularProgress/> :
            <Card>
                <CardMedia
                    component="img"
                    image={imgSrc}
                    alt={template.name}
                />
                <CardHeader
                    title={template.name}
                    subheader={template.immutable_data.description}
                    titleTypographyProps={{align: 'center'}}
                    subheaderTypographyProps={{
                        align: 'center',
                    }}
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[200]
                                : theme.palette.grey[700],
                    }}
                />
                <CardContent>
                    <Typography
                        variant="h5"
                        color="text.primary"
                        align="center"
                    >
                        {waxPrice.toFixed(2)} WAX
                    </Typography>
                    <Typography
                        variant="h6"
                        color="text.secondary"
                        align="center"
                    >
                        ({drop.listing_price})
                    </Typography>
                    <Typography
                        component={'p'}
                        variant="subtitle1"
                        align="center"
                    >
                        {packsRemained} packs available
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button
                        fullWidth
                        variant={'contained'}
                        onClick={buyDrop}
                    >
                        Buy
                    </Button>
                </CardActions>
            </Card>
        }
    </Grid>
}

DropShort.propTypes = {
    id: PropTypes.number.isRequired,
    ual: PropTypes.object.isRequired,
}
export default withUAL(DropShort)
