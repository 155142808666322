import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import CardMedia from '@mui/material/CardMedia'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import {IPFS_URL} from '../../utils/constants'

const CoinShort = (props) => {
    console.log('render Coin Short')
    let imgSrc = props.item.data.img ? `${IPFS_URL}${props.item.data.img}` : '/images/image-placeholder.png'

    return <Grid
        item
        key={props.item.asset_id}
        xs={12}
        sm={6}
        md={4}
    >
        <Card>
            <CardMedia
                component="img"
                image={imgSrc}
                alt={props.item.name}
            />
            <CardHeader
                title={props.item.name}
                titleTypographyProps={{align: 'center'}}
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[200]
                            : theme.palette.grey[700],
                }}
            />
            <CardContent>
                <Typography
                    component={'p'}
                    variant="subtitle1"
                    align="center"
                >
                    Country: {props.item.data.country}
                </Typography>
                <Typography
                    component={'p'}
                    variant="subtitle1"
                    align="center"
                >
                    Rarity: {props.item.data.rarity}
                </Typography>
                <Typography
                    component={'p'}
                    variant="subtitle1"
                    align="center"
                >
                    Year: {props.item.data.year}
                </Typography>
            </CardContent>
        </Card>
    </Grid>
}

CoinShort.propTypes = {
    item: PropTypes.object.isRequired,
}
export default CoinShort
