export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
}
export const sendingTransaction = async (tr, dispatch, activeUser, callback) => {
    /*
      dispatch(setModalText('Signing transaction...'))
      dispatch(setModalType('loading'))
    */
    try {
        let result = await activeUser.signTransaction(tr, {
            broadcast: true,
            blocksBehind: 3,
            expireSeconds: 120,
        })
        if (result.status && result.status === 'executed') {
            await callback()
//      dispatch(setModalType(null))
        } else {
            /*
                  dispatch(setModalError({name: 'Transaction error', message: result}))
                  dispatch(setModalType('error'))
            */
        }
    } catch (error) {
        /*
            dispatch(setModalError({name: error.name, message: error.message}))
            dispatch(setModalType('error'))
        */
    }
//  dispatch(setModalText(null))
}
export const log = (msg) => {
    if (process.env.NODE_ENV !== "production") {
        console.log(msg)
    }
}