import React, {useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {withUAL} from 'ual-reactjs-renderer'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import {getUserCoins, requestUserCoins, setUserCoins} from '../../store/atomicAssetsSlice'
import {CircularProgress} from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CoinShort from './CoinShort'
import PageHeader from '../PageHeader'

const Coins = (props) => {
    console.log('render Coins')
    const coins = useSelector(getUserCoins)

    const dispatch = useDispatch()

    useEffect(() => {
        console.log('Coins useEffect')
        if (props.ual.activeUser) {
            dispatch(requestUserCoins(props.ual.activeUser.accountName))
        }
        return () => {
            dispatch(setUserCoins(null))
        }
    }, [props.ual.activeUser])

    const coinList = useMemo(() => {
        return coins ? coins.map((item) => (
            <CoinShort
                key={item.asset_id}
                item={item}
                ual={props.ual}
            />
        )) : []
    }, [coins])

    return <Container maxWidth="md" component="main" sx={{mt: 5}}>
        <PageHeader title={'Coins'}/>
        {props.ual.activeUser ?
            coins ?
                coinList.length > 0 ?
                    <Grid container spacing={5} alignItems="flex-end">
                        {coinList}
                    </Grid>
                    :
                    <Box sx={{textAlign: 'center'}}>
                        <Typography>
                            {'You haven\'t any coins'}
                        </Typography>
                    </Box>
                :
                <Box sx={{textAlign: 'center'}}>
                    <CircularProgress/>
                </Box>
            :
            <Box sx={{textAlign: 'center'}}>
                <Typography>
                    You must be login
                </Typography>
            </Box>
        }
    </Container>
}

Coins.propTypes = {
    ual: PropTypes.object.isRequired,
}
export default withUAL(Coins)
