import {ExplorerApi} from 'atomicassets'

class AtomicAssetsApi {
    constructor() {
        this.collection = 'numismatiums'
        this.coinsSchema = 'uscoins'
        this.packsSchema = 'coinpacks'
        this.api = new ExplorerApi('https://wax.api.atomicassets.io', 'atomicassets', {fetch})
    }

    async getAccountAssetsCount(name) {
        let account = await this.api.getAccount(name)
        let result = 0
        for (let i in account.collections) {
            if (account.collections[i].collection.collection_name === this.collection) {
                result = account.collections[i].assets
                break
            }
        }
        return result
    }

    async getAsset(id) {
        return await this.api.getAsset(id)
    }

    async getNSMCollections() {
        return await this.api.getSchemas({'collection_name': this.collection, 'match': 'coins'})
    }

    async getPacks() {
        return await this.api.getTemplates({'collection_name': this.collection, 'schema_name': this.packsSchema})
    }

    async getSchema(schemaName) {
        return await this.api.getSchema(this.collection, schemaName)
    }

    async getTemplate(id) {
        return await this.api.getTemplate(this.collection, id)
    }

    async getTemplates(schemaName) {
        return await this.api.getTemplates({'collection_name': this.collection, 'schema_name': schemaName})
    }

    async getUserCoins(login) {
        return await this.api.getAssets({'owner': login, 'collection_name': this.collection, 'schema_name': this.coinsSchema})
    }

    async getUserPacks(login) {
        return await this.api.getAssets({'owner': login, 'collection_name': this.collection, 'schema_name': this.packsSchema})
    }
}

export const atomicAssetsApi = new AtomicAssetsApi()
