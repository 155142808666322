import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {atomicAssetsApi} from '../api/atomicAssetsApi'
import {waxApi} from '../api/waxApi'

const initialState = {
    auctions: null,
    dropDetails: {},
    templates: {},
    unclaimed: null,
    userCoins: null,
    userPacks: null,
}

export const requestAuctions = createAsyncThunk(
    'atomicAssets/requestAuctions',
    async () => {
        let auctions = await waxApi.api.rpc.get_table_rows({
            code: 'thehousemrkt',
            table: 'auctions',
            scope: 'thehousemrkt',
        })
        if (!auctions.rows) {
            return []
        }
        for (let i in auctions.rows) {
            const item = auctions.rows[i]
            const asset = await atomicAssetsApi.getAsset(item.asset_id)
            auctions.rows[i] = {...item, ...asset}
        }
        return auctions.rows
    }
)
export const requestDrop = createAsyncThunk(
    'atomicAssets/requestDrop',
    async (id) => {
        let result = await waxApi.api.rpc.get_table_rows({
            code: 'atomicdropsx',
            table: 'drops',
            scope: 'atomicdropsx',
            lower_bound: id,
            limit: 1,
        })
        let drop = {}
        if (result.rows && result.rows.length === 1 && result.rows[0].drop_id === id) {
            drop = result.rows[0]
        }
        return drop
    }
)
export const requestTemplate = createAsyncThunk(
    'atomicAssets/requestTemplate',
    async (id) => {
        return await atomicAssetsApi.getTemplate(id)
    }
)
export const requestUnclaimed = createAsyncThunk(
    'atomicAssets/requestUnclaimed',
    async (account) => {
        let unclaimed = await waxApi.api.rpc.get_table_rows({
            code: 'atomicpacksx',
            table: 'unboxpacks',
            scope: 'atomicpacksx',
            key_type: 'i64',
            index_position: 2,
            lower_bound: account,
            upper_bound: account
        })
        if (!unclaimed.rows) {
            return []
        }
        for (let i in unclaimed.rows) {
            const item = unclaimed.rows[i]
            const asset = await atomicAssetsApi.getAsset(item.pack_asset_id)
            unclaimed.rows[i] = {...item, ...asset}
        }
        return unclaimed.rows
    }
)
export const requestUserCoins = createAsyncThunk(
    'atomicAssets/requestUserCoins',
    async (account) => {
        return await atomicAssetsApi.getUserCoins(account)
    }
)
export const requestUserPacks = createAsyncThunk(
    'atomicAssets/requestUserPacks',
    async (account) => {
        return await atomicAssetsApi.getUserPacks(account)
    }
)

export const appSlice = createSlice({
    name: 'atomicAssets',
    initialState,
    reducers: {
        setAuctions: (state, action) => {
            state.auctions = action.payload
        },
        setDropDetail: (state, action) => {
            state.dropDetails = {...state.dropDetails}
            state.dropDetails[action.payload.id] = action.payload.detail
        },
        setTemplate: (state, action) => {
            state.templates = {...state.templates}
            state.templates[action.payload.id] = action.payload.template
        },
        setUnclaimed: (state, action) => {
            state.unclaimed = action.payload
        },
        setUserCoins: (state, action) => {
            state.userCoins = action.payload
        },
        setUserPacks: (state, action) => {
            state.userPacks = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(requestAuctions.fulfilled, (state, action) => {
            state.auctions = action.payload
        })
        builder.addCase(requestDrop.fulfilled, (state, action) => {
            let newDrop = {}
            newDrop[action.payload.drop_id] = {...action.payload}
            state.dropDetails = {...state.dropDetails, ...newDrop}
        })
        builder.addCase(requestTemplate.fulfilled, (state, action) => {
            let newTemplate = {}
            newTemplate[action.payload.template_id] = {...action.payload}
            state.templates = {...state.templates, ...newTemplate}
        })
        builder.addCase(requestUnclaimed.fulfilled, (state, action) => {
            state.unclaimed = action.payload
        })
        builder.addCase(requestUserCoins.fulfilled, (state, action) => {
            state.userCoins = action.payload
        })
        builder.addCase(requestUserPacks.fulfilled, (state, action) => {
            state.userPacks = action.payload
        })
    },
})

export const getAuctions = (state) => state.atomicAssets.auctions
export const getDropDetail = (id) => {
    return (state) => state.atomicAssets.dropDetails[id]
}
export const getTemplate = (id) => {
    return (state) => state.atomicAssets.templates[id]
}
export const getUnclaimed = (state) => state.atomicAssets.unclaimed
export const getUserCoins = (state) => state.atomicAssets.userCoins
export const getUserPacks = (state) => state.atomicAssets.userPacks

export const {setAuctions, setDropDetail, setTemplate, setUnclaimed, setUserCoins, setUserPacks} = appSlice.actions

export default appSlice.reducer
