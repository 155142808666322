import React, {useEffect} from 'react'
import {Route, Routes} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {withUAL} from 'ual-reactjs-renderer'
import PropTypes from 'prop-types'
import CssBaseline from '@mui/material/CssBaseline'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import {
    getLoaded,
    initialize,
    requestMarketBalances,
    requestWaxBalance,
    resetMarketBalances,
    resetWaxBalance
} from './store/appSlice'
import {CircularProgress} from '@mui/material'
import DefaultLayout from './layouts/DefaultLayout'
import NotFound from './components/NotFound'
import About from './components/About'
import Packs from './components/atomicAssets/Packs'
import Unclaimed from './components/atomicAssets/Unclaimed'
import Coins from './components/atomicAssets/Coins'
import Auctions from './components/auctions/Auctions'
import {log} from './utils/helpers'
import Profile from './components/account/Profile'

const App = (props) => {
    log('render App')
    const loaded = useSelector(getLoaded)

    const dispatch = useDispatch()

    useEffect(() => {
        log('App useEffect')
        dispatch(initialize())
    }, [])
    useEffect(() => {
        log('App useEffect props.ual.activeUser')
        if (props.ual.activeUser) {
            dispatch(requestWaxBalance(props.ual.activeUser.accountName))
            dispatch(requestMarketBalances(props.ual.activeUser.accountName))
        }
        return () => {
            dispatch(resetWaxBalance())
            dispatch(resetMarketBalances())
        }
    }, [props.ual.activeUser])

    if (!loaded) {
        return <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <CircularProgress/>
            </Box>
        </Container>
    }

    return <Routes>
        <Route path="/" element={<DefaultLayout/>}>
            <Route exact path="/" element={<About/>}/>
            <Route exact path="/auctions" element={<Auctions/>}/>
            <Route exact path="/profile" element={<Profile/>}/>
            <Route exact path="/coins" element={<Coins/>}/>
            <Route exact path="/packs" element={<Packs/>}/>
            <Route exact path="/unclaimed" element={<Unclaimed/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Route>
    </Routes>
}

App.propTypes = {
    ual: PropTypes.object.isRequired,
}
export default withUAL(App)
