import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {withUAL} from 'ual-reactjs-renderer'
import Container from '@mui/material/Container'
import {CircularProgress} from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {log} from '../../utils/helpers'
import {getMarketBalances} from '../../store/appSlice'
import MarketBalanceShort from './MarketBalanceShort'
import PageHeader from '../PageHeader'

const Profile = (props) => {
    log('render Profile')
    const marketBalances = useSelector(getMarketBalances)

    const balanceList = useMemo(() => {
        return marketBalances ? marketBalances.map((item) => (
            <MarketBalanceShort
                key={item.token_contract}
                item={item}
            />
        )) : []
    }, [marketBalances])

    return <Container maxWidth="md" component="main" sx={{mt: 5}}>
        <PageHeader title={'Your balances'}/>
        {props.ual.activeUser ?
            marketBalances ?
                <Box>
                    {balanceList.length > 0 ?
                        balanceList
                        :
                        <Typography>
                            {'You haven\'t any balances'}
                        </Typography>
                    }
                </Box>
                :
                <Box sx={{textAlign: 'center'}}>
                    <CircularProgress/>
                </Box>
            :
            <Box sx={{textAlign: 'center'}}>
                <Typography>
                    You must be login
                </Typography>
            </Box>
        }
    </Container>
}

Profile.propTypes = {
    ual: PropTypes.object.isRequired,
}
export default withUAL(Profile)
