import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const PageHeader = (props) => {
    return <Box sx={{textAlign: 'center', mb: 3}}>
        <Typography variant={'h5'}>
            {props.title}
        </Typography>
    </Box>
}

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
}
export default PageHeader
