import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {waxApi} from '../api/waxApi'

const initialState = {
    delphiMedian: null,
    loaded: false,
    marketBalances: null,
    waxBalance: null,
}

export const initialize = createAsyncThunk(
    'app/initialize',
    async (_, {dispatch}) => {
        setInterval(() => {dispatch(requestDelphiMedian())}, 30000)
        await Promise.all([
            dispatch(requestDelphiMedian())
        ])
        return true
    }
)
export const requestDelphiMedian = createAsyncThunk(
    'app/requestDelphiMedian',
    async () => {
        let delphi = await waxApi.api.rpc.get_table_rows({
            code: 'delphioracle',
            table: 'datapoints',
            scope: 'waxpusd',
            limit: 21,
        })
        if (!delphi.rows || delphi.rows.length === 0) {
            return null
        }
        let delphiMedian = 0
        delphi.rows.forEach((item) => {
            delphiMedian += item.median
        })
        delphiMedian = Math.floor(delphiMedian / delphi.rows.length)
        console.log(delphiMedian)
        return delphiMedian
    }
)
export const requestMarketBalances = createAsyncThunk(
    'app/requestMarketBalances',
    async (account) => {
        const result = await waxApi.api.rpc.get_table_rows({
            code: 'thehousemrkt',
            table: 'balances',
            scope: account,
        })
        let balances = []
        for (let i in result.rows) {
            const quantity = result.rows[i].quantity
            if (parseFloat(quantity) > 0) {
                balances.push(result.rows[i])
            }
        }
        return balances
    }
)
export const requestWaxBalance = createAsyncThunk(
    'app/requestWaxBalance',
    async (account) => {
        let balances = await waxApi.api.rpc.get_table_rows({
            code: 'eosio.token',
            table: 'accounts',
            scope: account,
        })
        for (let i in balances.rows) {
            const balance = balances.rows[i].balance.split(' ')
            if (balance.length === 2 && balance[1] === 'WAX') {
                return parseFloat(balance[0])
            }
        }
        return 0
    }
)

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        resetMarketBalances: (state) => {
            state.marketBalances = null
        },
        resetWaxBalance: (state) => {
            state.waxBalance = null
        },
    },
    extraReducers: (builder) => {
        builder.addCase(initialize.fulfilled, (state, action) => {
            console.log(action.payload)
            state.loaded = action.payload
        })
        builder.addCase(requestDelphiMedian.fulfilled, (state, action) => {
            state.delphiMedian = action.payload
        })
        builder.addCase(requestMarketBalances.fulfilled, (state, action) => {
            state.marketBalances = action.payload
        })
        builder.addCase(requestWaxBalance.fulfilled, (state, action) => {
            state.waxBalance = action.payload
        })
    },
})

export const getDelphiMedian = (state) => state.app.delphiMedian
export const getLoaded = (state) => state.app.loaded
export const getMarketBalances = (state) => state.app.marketBalances
export const getWaxBalance = (state) => state.app.waxBalance

export const {resetMarketBalances, resetWaxBalance} = appSlice.actions

export default appSlice.reducer

