import React from 'react'
import PropTypes from 'prop-types'
import {withUAL} from 'ual-reactjs-renderer'
import {useDispatch} from 'react-redux'
import Button from '@mui/material/Button'
import {log, sendingTransaction, sleep} from '../../utils/helpers'
import Typography from '@mui/material/Typography'
import {requestMarketBalances} from '../../store/appSlice'

const MarketBalanceShort = (props) => {
    log('render MarketBalanceShort')
    const dispatch = useDispatch()

    const withdraw = () => {
        const tr = {
            actions: [{
                account: 'thehousemrkt',
                name: 'withdraw',
                authorization: [{
                    actor: props.ual.activeUser.accountName,
                    permission: 'active',
                }],
                data: {
                    owner: props.ual.activeUser.accountName,
                    token_contract: props.item.token_contract,
                    quantity: props.item.quantity,
                }
            }],
        }
        const callback = async () => {
            await sleep(3000)
            dispatch(requestMarketBalances(props.ual.activeUser.accountName))
        }
        sendingTransaction(tr, dispatch, props.ual.activeUser, callback)
    }

    return <Typography>
        {props.item.quantity}
        <Button
            variant="outlined"
            sx={{ml: 1}}
            onClick={withdraw}
        >Claim</Button>
    </Typography>
}

MarketBalanceShort.propTypes = {
    item: PropTypes.object.isRequired,
    ual: PropTypes.object.isRequired,
}
export default withUAL(MarketBalanceShort)
