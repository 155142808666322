import React, {useState} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'
import {Link as RouterLink} from 'react-router-dom'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {withUAL} from 'ual-reactjs-renderer'
import GlobalStyles from '@mui/material/GlobalStyles'
import CssBaseline from '@mui/material/CssBaseline'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import TelegramIcon from '@mui/icons-material/Telegram'
import Box from '@mui/material/Box'
import {Badge, Divider, Menu, MenuItem} from '@mui/material'
import {getMarketBalances} from '../store/appSlice'

const DefaultLayout = (props) => {
    console.log('render DefaultLayout')
    const [anchorElUser, setAnchorElUser] = useState(null)
    const marketBalances = useSelector(getMarketBalances)
    const haveBalances = marketBalances && marketBalances.length > 0

    const navigate = useNavigate()

    const handleClickUserMenu = (url) => {
        handleCloseUserMenu()
        navigate(url)
    }
    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    return <>
        <GlobalStyles styles={{ul: {margin: 0, padding: 0, listStyle: 'none'}}}/>
        <CssBaseline/>
        <AppBar
            position="static"
            color="default"
            elevation={0}
            sx={{borderBottom: (theme) => `1px solid ${theme.palette.divider}`}}
        >
            <Toolbar sx={{flexWrap: 'wrap'}}>
                <Typography variant="h6" color="inherit" noWrap sx={{flexGrow: 1}}>
                    <Link
                        component={RouterLink}
                        to={'/'}
                        color="text.primary"
                        sx={{textDecoration: 'none'}}
                    >
                        Numismatium
                    </Link>
                </Typography>
                <nav>
                    <Link
                        component={RouterLink}
                        to={'/auctions'}
                        variant="button"
                        color="text.primary"
                        sx={{my: 1, mx: 1.5}}
                    >
                        Pack auctions
                    </Link>
                    <Link
                        variant="button"
                        color="text.primary"
                        href="https://game.numismatium.com"
                        target={'_blank'}
                        rel={'nofollow'}
                        sx={{my: 1, mx: 1.5}}
                    >
                        The House - play to earn
                    </Link>
                </nav>
                {props.ual.activeUser ?
                    <Box sx={{flexGrow: 0}}>
                        <Button
                            variant="outlined"
                            sx={{my: 1, mx: 1.5}}
                            onClick={handleOpenUserMenu}
                        >
                            <Badge color="primary" variant="dot" invisible={!haveBalances}>
                                {props.ual.activeUser.accountName}
                            </Badge>
                        </Button>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={!!anchorElUser}
                            onClose={handleCloseUserMenu}
                        >
                            {haveBalances ?
                                <MenuItem key={'claim'} onClick={() => {
                                    handleClickUserMenu('/profile/')
                                }}>
                                    <Typography textAlign="center">
                                        <Badge color="primary" variant="dot" invisible={!haveBalances}>
                                            Claim balances
                                        </Badge>
                                    </Typography>
                                </MenuItem>
                                :
                                null
                            }
                            <MenuItem key={'packs'} onClick={() => {
                                handleClickUserMenu('/packs/')
                            }}>
                                <Typography textAlign="center">
                                    Your packs
                                </Typography>
                            </MenuItem>
                            <MenuItem key={'unclaimed'} onClick={() => {
                                handleClickUserMenu('/unclaimed/')
                            }}>
                                <Typography textAlign="center">
                                    Unclaimed coins
                                </Typography>
                            </MenuItem>
                            <MenuItem key={'coins'} onClick={() => {
                                handleClickUserMenu('/coins/')
                            }}>
                                <Typography textAlign="center">
                                    Your coins
                                </Typography>
                            </MenuItem>
                            <Divider/>
                            <MenuItem key={'logout'} onClick={() => {
                                props.ual.logout()
                                handleCloseUserMenu()
                            }}>
                                <Typography textAlign="center">
                                    Logout
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    :
                    <Button
                        variant="outlined"
                        sx={{my: 1, mx: 1.5}}
                        onClick={props.ual.showModal}
                    >
                        Login
                    </Button>
                }
            </Toolbar>
        </AppBar>
        <Outlet/>
        {/* Hero unit */}
        {/* Footer */}
        <Container
            maxWidth="md"
            component="footer"
            sx={{
                borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                mt: 5,
                py: [3, 3],
            }}
        >
            <Grid container spacing={4} justifyContent="space-evenly" sx={{mb: 3}}>
                <Grid item xs={6} sm={3} key={0}>
                    <Link
                        href={'https://t.me/numismatium_chat'}
                        target={'_blank'}
                        rel={'nofollow'}
                        variant="button"
                        color="text.primary"
                    >
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            <TelegramIcon/>Group
                        </Typography>
                    </Link>
                </Grid>
                <Grid item xs={6} sm={3} key={1}>
                    <Link
                        href={'https://t.me/numismatium'}
                        target={'_blank'}
                        rel={'nofollow'}
                        variant="button"
                        color="text.primary"
                    >
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            <TelegramIcon/>Channel
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
            <Typography variant="body2" color="text.secondary" align="center">
                {'Copyright © '}
                <Link component={RouterLink} color="inherit" to="/">
                    Numismatium
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </Container>
        {/* End footer */}
    </>
}

DefaultLayout.propTypes = {
    ual: PropTypes.object.isRequired,
}
export default withUAL(DefaultLayout)
