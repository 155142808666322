import React, {useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {withUAL} from 'ual-reactjs-renderer'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import {getUserPacks, requestUserPacks, setUserPacks} from '../../store/atomicAssetsSlice'
import PackShort from './PackShort'
import {CircularProgress} from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import PageHeader from '../PageHeader'

const Packs = (props) => {
    console.log('render Packs')
    const packs = useSelector(getUserPacks)

    const dispatch = useDispatch()

    useEffect(() => {
        if (props.ual.activeUser) {
            dispatch(requestUserPacks(props.ual.activeUser.accountName))
        }
        return () => {
            dispatch(setUserPacks(null))
        }
    }, [props.ual.activeUser])

    const packList = useMemo(() => {
        return packs ? packs.map((item) => (
            <PackShort
                key={item.asset_id}
                item={item}
                ual={props.ual}
            />
        )) : []
    }, [packs])
    console.log(packs)

    return <Container maxWidth="md" component="main" sx={{mt: 5}}>
        <PageHeader title={'Packs'}/>
        {props.ual.activeUser ?
            packs ?
                packList.length > 0 ?
                    <Grid container spacing={5} alignItems="flex-end">
                        {packList}
                    </Grid>
                    :
                    <Box sx={{textAlign: 'center'}}>
                        <Typography>
                            {'You haven\'t any packs'}
                        </Typography>
                    </Box>
                :
                <Box sx={{textAlign: 'center'}}>
                    <CircularProgress/>
                </Box>
            :
            <Box sx={{textAlign: 'center'}}>
                <Typography>
                    You must be login
                </Typography>
            </Box>
        }
    </Container>
}

Packs.propTypes = {
    ual: PropTypes.object.isRequired,
}
export default withUAL(Packs)
